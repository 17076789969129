import React from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SchemaOrg from './SchemaOrg';

const SEO = ({ postData, isBlogPost, pageTitle, pageDescription, socialImage }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author
            image
            twitter_image
            linkedin_image
            organization {
              name
              url
              logo
            }
          }
        }
       }
    `}

  render={( { site: { siteMetadata: seo }, blogPost} ) => {
      const title = postData.title || pageTitle || seo.title;
      const description = postData.excerpt || pageDescription || seo.description;
      let postImage = null;
      if (postData?.image){
        postImage = postData?.image?.childImageSharp?.fluid?.src;
      }
      const image = postImage ? `${seo.siteUrl}${postImage}` : `${seo.siteUrl}${seo.image}`;

      const url = postData.slug
        ? `${seo.siteUrl}/${postData.slug}`
        : seo.siteUrl;
      // TODO: enable real date modified
      const datePublished = isBlogPost ? moment(postData.date).format("YYYY-MM-DD") : false;

      let socialImageDisplay;
      if (socialImage){
        socialImageDisplay = `${seo.siteUrl}${socialImage}`
      }

    let twitterSocialImage = `${seo.siteUrl}${seo?.twitter_image}`;
    if (postImage){
      twitterSocialImage = `${seo.siteUrl}${postImage}`
    }

    let linkedInSocialImage = `${seo.siteUrl}${seo?.linkedin_image}`;
    if (postImage){
      linkedInSocialImage = `${seo.siteUrl}${postImage}`;
    }

      return (
        <React.Fragment>
          <Helmet>
            {/* General tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={socialImageDisplay ? socialImageDisplay : image} />

            {/* OpenGraph tags - includes LinkedIn */}
            <meta property="og:url" content={url} />
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={socialImageDisplay ? socialImageDisplay : linkedInSocialImage} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={socialImageDisplay ? socialImageDisplay : twitterSocialImage} />

          </Helmet>
          <SchemaOrg
            isBlogPost={isBlogPost}
            url={url}
            title={title}
            //image={image}
            description={description}
            datePublished={datePublished}
            dateModified={datePublished}
            siteUrl={seo.siteUrl}
            author={seo.author}
            organization={seo.organization}
            defaultTitle={seo.title}
          />
        </React.Fragment>
      );
    }}
  />
);

SEO.propTypes = {
  isBlogPost: PropTypes.bool,
  postData: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.any,
      excerpt: PropTypes.any,
      dateModified: PropTypes.any
    }),
  }),
  postImage: PropTypes.string,
};

SEO.defaultProps = {
  isBlogPost: false,
  postData: { childMarkdownRemark: {} },
  postImage: null,
};

export default SEO;
